<template>
  <LayoutManager>
    <div class="ml-main w-100" id="my-achievements">
      <b-row class="m-0">
        <b-col cols="12">
          <div class="ma-title">
            <div class="d-block d-md-none d-lg-none ma-title--content">
              {{ $t("general.menu.my_learning") }}
            </div>
            <div class="d-none d-md-block d-lg-block ma-title--content">
              {{ $t("general.menu.my_achievements") }}
            </div>
          </div>
          <div class="ml-main__loader" v-if="isLoading">
            <Loader />
          </div>

          <div
            :class="[
              'd-none',
              { 'd-md-block d-lg-block': certificatesList.length > 0 },
              {
                'd-md-flex d-lg-flex align-items-center justify-content-center':
                  certificatesList.length === 0
              }
            ]"
            v-else
          >
            <div
              class="ld-sec__achievements"
              v-if="certificatesList.length > 0"
            >
              <AchievementsCard
                v-for="(c, i) in certificatesList"
                :key="i"
                :credential="c"
                :index="i"
                @click="
                  navigateTo('my-achievement-details', c.course_key_string)
                "
              />
            </div>
            <div class="achievement-empty-sec" v-else>
              <div>
                <img
                  src="@/assets/images/not-found/achievement-not-found.svg"
                  alt="not found"
                  width="auto"
                  height="auto"
                />
                <div class="empty-sec-title w-50 mx-auto">
                  {{ $t("my_achievements.empty.title") }}
                </div>
                <LxpButton
                  variant="outline-primary"
                  @click="$router.push({ path: '/course-discovery' })"
                  className="achievement-empty-sec__btn"
                  >{{ $t("my_achievements.empty.button") }}</LxpButton
                >
              </div>
            </div>
          </div>

          <div class="d-block d-md-none d-lg-none">
            <div class="ld-sec__tabs mt-0">
              <b-tabs
                content-class="mt-3"
                class="d-block d-md-none"
                v-model="activeTab"
              >
                <b-tab
                  title="My programmes"
                  @click="navigateTo('my-programmes')"
                >
                </b-tab>
                <b-tab title="My Achievements" class="active">
                  <div
                    class="ld-sec__achievements"
                    v-if="certificatesList.length > 0"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        v-for="(c, i) in certificatesList"
                        :key="i"
                      >
                        <AchievementsCard
                          :credential="c"
                          :index="i"
                          @click="
                            navigateTo(
                              'my-achievement-details',
                              c.course_key_string
                            )
                          "
                        />
                      </b-col>
                    </b-row>
                  </div>
                  <div class="achievement-empty-sec" v-else>
                    <div>
                      <img
                        src="@/assets/images/not-found/achievement-not-found.svg"
                        alt="not found"
                        width="auto"
                        height="auto"
                      />
                      <div class="empty-sec-title w-50 mx-auto">
                        {{ $t("my_achievements.empty.title") }}
                      </div>
                      <b-button
                        class="btn ob-btn empty-sec-button"
                        @click="navigateTo('course-discovery')"
                        >{{ $t("my_achievements.empty.button") }}</b-button
                      >
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </LayoutManager>
</template>
<script>
import { mapGetters } from "vuex";
import { LxpButton } from "didactica";
import LayoutManager from "../components/Layout/LayoutManager.vue";
import AchievementsCard from "@/components/Achievements/AchievementCard.vue";

export default {
  components: {
    LayoutManager,
    AchievementsCard,
    LxpButton
  },
  data() {
    return {
      tabIndex: 0,
      isLoading: false,
      activeTab: 2
    };
  },
  computed: {
    ...mapGetters(["certificatesList", "language"])
  },
  mounted() {
    if (this.certificatesList.length === 0) {
      this.getAchievements();
    }
  },
  methods: {
    navigateTo(name, params) {
      this.$router.push({ name: name, params: { id: params } }).catch(() => {});
    },
    openWin() {
      let routeData = this.$router.resolve({
        path: `/badge-check`
      });
      window.open(routeData.href, "_blank");
    },
    openCredential(a) {
      let routeData = this.$router.resolve({
        path: `/certificate`,
        query: { key: a.key, lang: this.language }
      });
      window.open(routeData.href, "_blank");
    },
    getAchievements() {
      this.isLoading = true;
      this.$store.dispatch("getAllCertificates").then(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ml-main {
  .ld-sec__achievements {
    display: flex;
    flex-wrap: wrap;
    margin: 0 52px;
  }
  .ml-main__loader {
    height: 300px;
  }
  .ma-title {
    @include subtitle-large;
    text-align: left;
    margin: 18px 0 24px 52px;
    color: $brand-public-secondary;
  }
  .mp-result-found {
    text-align: left;
    margin: 0 0 35px 20px;
    @include label-large;
    color: $brand-public-disabled;
  }
  .achievement-empty-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    img {
      margin-bottom: 36px;
    }
    .empty-sec-title {
      @include body-regular($brand-neutral-900, 500);
      text-align: center;
      margin-bottom: 36px;
    }
    .achievement-empty-sec__btn {
      margin: 0 auto;
    }
  }
  .ld-sec__tabs {
    margin-bottom: 20px;
    position: relative;
    .tabs {
      .nav-tabs {
        width: calc(100% - 100px);
        margin: 24px 0 0 20px;
        .nav-item {
          margin-bottom: 20px;
          margin-right: 10px;
          .nav-link {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1.25px;
            padding: 8px 16px;
            &.active {
              color: $brand-primary-400;
              background: $brand-primary-100;
              mix-blend-mode: normal;
              border-radius: 8px;
            }
            &::after {
              content: "";
              border-bottom: 0px solid;
              display: block;
              position: relative;
              top: 0px;
            }
          }
        }
      }
    }
    .d-block {
      padding: 0 0 0 15px;
      .course-card {
        // width: 25%;
        margin-bottom: 24px;
        float: left;
      }
      .slider {
        text-align: left;
        display: flex;
        justify-content: space-between;
        div {
          &:nth-child(3n) {
            .ac-card {
              margin-right: 0;
            }
          }
        }
      }
      .ld-sec__achievements {
        display: flex;
        margin: 0 16px;
      }
    }
    .ml-verify__btn {
      position: absolute;
      top: 0;
      right: 15px;
      &.ml-verify__grey {
        border-color: #a0a4a8;
        color: #a0a4a8;
      }
    }
  }
}

#achievement-modal {
  .modal-header {
    padding: 24px 24px 0;
  }
  .modal-content {
    border: 0px;
    .modal-body {
      padding: 0 1rem;
      .ac-div {
        padding: 10px 0px 20px;
        .ac-card__title {
          padding-bottom: 10px;
        }
        .ac-card__timeline {
          border-top: 0px;
        }
        .card {
          width: 100%;
          border-radius: 8px;
          border: 1px solid #acacac;
          .card-body {
            padding: 15px;
            .ac-mc__title {
              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 1.25px;
                text-transform: uppercase;
                color: #44464e;
              }
              label {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #191c1d;
              }
            }
            .ac-mc__list {
              .btn-outline-primary {
                border: 1px solid #0057e0;
                box-sizing: border-box;
                padding: 10px 24px;
                min-width: auto !important;
              }
            }
          }
          &:hover {
            background: linear-gradient(
                0deg,
                rgba(0, 87, 224, 0.08),
                rgba(0, 87, 224, 0.08)
              ),
              #fbfdfd;
            border: 1px solid #0057e0;
          }
        }
      }
    }
  }
}

// rtl layout
[dir="rtl"] {
  .ml-main {
    .ld-sec__tabs {
      .d-block {
        .course-card {
          float: right;
        }
      }
    }
    .my-achievements__breadcrumb {
      margin: 12px 52px 0 0;
    }
    .ma-title {
      margin: 0px 52px 24px 0;
      :deep .lxp-alert {
        .lxp-alert__content {
          img {
            margin: 0 0 0 16px;
          }
          .lxp-alert__content--right {
            text-align: right;
          }
        }
      }
      .ma-title--content {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .ml-main {
    .ld-sec__tabs .d-block .ld-sec__achievements {
      margin: 0;
    }
    .ma-title {
      position: sticky;
      top: 0px;
      line-height: 33px;
      margin: 0;
      letter-spacing: 0.0025em;
    }

    .ld-sec__tabs {
      margin-bottom: 32px;
      .tabs {
        .nav-tabs {
          width: 100%;
          margin: 18px 0 16px;
          padding: 14px 0;
          .nav-item {
            margin-right: 0;
            margin-bottom: 0;
          }
        }
      }
      .d-block {
        padding: 0;
        .course-card {
          width: 100%;
          margin-bottom: 24px;
          float: none;
        }
      }
      .responsive-course-card-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        letter-spacing: 0.15px;
        margin-bottom: 15px;
        color: $brand-neutral-700;
      }
      .responsive-course-card {
        display: flex;
        overflow: auto;
      }
    }
  }
}
</style>
